$color-lime-darker: #204500;
$color-lime-dark: #427f0d;
$color-lime-base: #5ca120;
$color-lime-light: #b4de92;
$color-lime-lighter: #e5f1db;
$color-lime-backgrounds: #f1f4ef;
$color-lime-backgrounds-containers: #ffffff;
$color-lime-backgrounds-disabled: #e9e9e9;
$color-lime-backgrounds-hover: #f0f4ed;
$color-lime-title: #2b5508;
$color-lime-subtitle: #4da302;
$color-lime-text: #606f53;
$color-lime-on-default: #a7bb96;
$color-lime-on-primary: #ffffff;
$color-lime-on-secondary: #ffffff;
$color-lime-on-tertiary: #ffffff;
$color-lime-on-hover: #6a775f;
$color-lime-on-disabled: #a0a0a0;

$color-background-stepper: #cdcccc;

$color-green-darker: #0d4c28;
$color-green-dark: #247446;
$color-green-base: #2f995c;
$color-green-light: #95d5b0;
$color-green-lighter: #d6f1e1;
$color-green-backgrounds: #eef6f1;
$color-green-backgrounds-containers: #ffffff;
$color-green-backgrounds-disabled: #e9e9e9;
$color-green-backgrounds-hover: #e2f6e9;
$color-green-title: #16763e;
$color-green-subtitle: #3cb46e;
$color-green-text: #4a6c58;
$color-green-on-default: #79a98d;
$color-green-on-primary: #ffffff;
$color-green-on-secondary: #ffffff;
$color-green-on-tertiary: #ffffff;
$color-green-on-hover: #5f836e;
$color-green-on-disabled: #a0a0a0;

$color-teal-darker: #095e5e;
$color-teal-dark: #0f9595;
$color-teal-base: #3acece;
$color-teal-light: #8fe0e0;
$color-teal-lighter: #d1ffff;
$color-teal-backgrounds: #e9f6f6;
$color-teal-backgrounds-containers: #ffffff;
$color-teal-backgrounds-disabled: #e9e9e9;
$color-teal-backgrounds-hover: #dff0f0;
$color-teal-title: #16b6b6;
$color-teal-subtitle: #5ee4e4;
$color-teal-text: #426363;
$color-teal-on-default: #5aa4a4;
$color-teal-on-primary: #ffffff;
$color-teal-on-secondary: #ffffff;
$color-teal-on-tertiary: #ffffff;
$color-teal-on-hover: #447676;
$color-teal-on-disabled: #a0a0a0;

$color-bluesky-darker: #02538d;
$color-bluesky-dark: #0074c8;
$color-bluesky-base: #28a3fc;
$color-bluesky-light: #9bc3ed;
$color-bluesky-lighter: #deeefa;
$color-bluesky-backgrounds: #f3f6f8;
$color-bluesky-backgrounds-containers: #ffffff;
$color-bluesky-backgrounds-disabled: #e9e9e9;
$color-bluesky-backgrounds-hover: #d1e4f1;
$color-bluesky-title: #0a7dd1;
$color-bluesky-subtitle: #46a3e7;
$color-bluesky-text: #466276;
$color-bluesky-on-default: #79add2;
$color-bluesky-on-primary: #ffffff;
$color-bluesky-on-secondary: #ffffff;
$color-bluesky-on-tertiary: #ffffff;
$color-bluesky-on-hover: #587a92;
$color-bluesky-on-disabled: #a0a0a0;

$color-blue-darker: #182765;
$color-blue-dark: #2b45b0;
$color-blue-base: #375ae8;
$color-blue-light: #c7d2ff;
$color-blue-lighter: #ecf0ff;
$color-blue-backgrounds: #f1f3fb;
$color-blue-backgrounds-containers: #ffffff;
$color-blue-backgrounds-disabled: #e9e9e9;
$color-blue-backgrounds-hover: #e4eaff;
$color-blue-title: #0a2592;
$color-blue-subtitle: #3059c8;
$color-blue-text: #60647d;
$color-blue-on-default: #818ec4;
$color-blue-on-primary: #ffffff;
$color-blue-on-secondary: #ffffff;
$color-blue-on-tertiary: #ffffff;
$color-blue-on-hover: #65729f;
$color-blue-on-disabled: #a0a0a0;

$color-indigo-darker: #291b7d;
$color-indigo-dark: #3b28b0;
$color-indigo-base: #5037e8;
$color-indigo-light: #a293ff;
$color-indigo-lighter: #dcd7ff;
$color-indigo-backgrounds: #f3f1fe;
$color-indigo-backgrounds-containers: #ffffff;
$color-indigo-backgrounds-disabled: #e9e9e9;
$color-indigo-backgrounds-hover: #eae8f6;
$color-indigo-title: #3f28ca;
$color-indigo-subtitle: #7460ea;
$color-indigo-text: #362d68;
$color-indigo-on-default: #877eb7;
$color-indigo-on-primary: #ffffff;
$color-indigo-on-secondary: #ffffff;
$color-indigo-on-tertiary: #ffffff;
$color-indigo-on-hover: #50487c;
$color-indigo-on-disabled: #a0a0a0;

$color-violet-darker: #300b81;
$color-violet-dark: #451c9d;
$color-violet-base: #783ce1;
$color-violet-light: #b4a6d2;
$color-violet-lighter: #ded9e9;
$color-violet-backgrounds: #f1eff3;
$color-violet-backgrounds-containers: #ffffff;
$color-violet-backgrounds-disabled: #e9e9e9;
$color-violet-backgrounds-hover: #ebe7ef;
$color-violet-title: #5128a7;
$color-violet-subtitle: #6f49bf;
$color-violet-text: #342751;
$color-violet-on-default: #7764a1;
$color-violet-on-primary: #ffffff;
$color-violet-on-secondary: #ffffff;
$color-violet-on-tertiary: #ffffff;
$color-violet-on-hover: #655587;
$color-violet-on-disabled: #a0a0a0;

$color-amethist-darker: #431766;
$color-amethist-dark: #5b1f8b;
$color-amethist-base: #7428b0;
$color-amethist-light: #ae8acb;
$color-amethist-lighter: #d9c6e9;
$color-amethist-backgrounds: #efecf1;
$color-amethist-backgrounds-containers: #ffffff;
$color-amethist-backgrounds-disabled: #e9e9e9;
$color-amethist-backgrounds-hover: #eae1f0;
$color-amethist-title: #58148e;
$color-amethist-subtitle: #813fb5;
$color-amethist-text: #3f3149;
$color-amethist-on-default: #9d7fb2;
$color-amethist-on-primary: #ffffff;
$color-amethist-on-secondary: #ffffff;
$color-amethist-on-tertiary: #ffffff;
$color-amethist-on-hover: #745a87;
$color-amethist-on-disabled: #a0a0a0;

$color-purple-darker: #3e0747;
$color-purple-dark: #5f0f6c;
$color-purple-base: #ac13c5;
$color-purple-light: #d6a9dd;
$color-purple-lighter: #e7dae9;
$color-purple-backgrounds: #f1f0f1;
$color-purple-backgrounds-containers: #ffffff;
$color-purple-backgrounds-disabled: #e9e9e9;
$color-purple-backgrounds-hover: #eee3f0;
$color-purple-title: #9211a7;
$color-purple-subtitle: #a959b6;
$color-purple-text: #55335a;
$color-purple-on-default: #9b5da4;
$color-purple-on-primary: #ffffff;
$color-purple-on-secondary: #ffffff;
$color-purple-on-tertiary: #ffffff;
$color-purple-on-hover: #77487e;
$color-purple-on-disabled: #a0a0a0;

$color-pink-darker: #7d024c;
$color-pink-dark: #aa1970;
$color-pink-base: #d752a2;
$color-pink-light: #d497bc;
$color-pink-lighter: #eed8e5;
$color-pink-backgrounds: #f4eff2;
$color-pink-backgrounds-containers: #ffffff;
$color-pink-backgrounds-disabled: #e9e9e9;
$color-pink-backgrounds-hover: #f6e8f1;
$color-pink-title: #930f5e;
$color-pink-subtitle: #b85691;
$color-pink-text: #512640;
$color-pink-on-default: #b987a5;
$color-pink-on-primary: #ffffff;
$color-pink-on-secondary: #ffffff;
$color-pink-on-tertiary: #ffffff;
$color-pink-on-hover: #906981;
$color-pink-on-disabled: #a0a0a0;

$color-rose-darker: #570c27;
$color-rose-dark: #90274d;
$color-rose-base: #b73765;
$color-rose-light: #be718d;
$color-rose-lighter: #e7aec2;
$color-rose-backgrounds: #fff1f6;
$color-rose-backgrounds-containers: #ffffff;
$color-rose-backgrounds-disabled: #e9e9e9;
$color-rose-backgrounds-hover: #fee6ee;
$color-rose-title: #9a1d4a;
$color-rose-subtitle: #d35c87;
$color-rose-text: #4a2935;
$color-rose-on-default: #9e5e75;
$color-rose-on-primary: #ffffff;
$color-rose-on-secondary: #ffffff;
$color-rose-on-tertiary: #ffffff;
$color-rose-on-hover: #824e61;
$color-rose-on-disabled: #a0a0a0;

$color-red-darker: #59060a;
$color-red-dark: #882227;
$color-red-base: #ad0810;
$color-red-light: #b96a6d;
$color-red-lighter: #dda9ab;
$color-red-backgrounds: #faedee;
$color-red-backgrounds-containers: #ffffff;
$color-red-backgrounds-disabled: #e9e9e9;
$color-red-backgrounds-hover: #ffe2e5;
$color-red-title: #971118;
$color-red-subtitle: #a35e61;
$color-red-text: #4f3738;
$color-red-on-default: #bc9697;
$color-red-on-primary: #ffffff;
$color-red-on-secondary: #ffffff;
$color-red-on-tertiary: #ffffff;
$color-red-on-hover: #987678;
$color-red-on-disabled: #a0a0a0;

$color-flame-darker: #7b3008;
$color-flame-dark: #b44509;
$color-flame-base: #f16920;
$color-flame-light: #f69561;
$color-flame-lighter: #ffd3bb;
$color-flame-backgrounds: #faf1ec;
$color-flame-backgrounds-containers: #ffffff;
$color-flame-backgrounds-disabled: #e9e9e9;
$color-flame-backgrounds-hover: #faece4;
$color-flame-title: #a84f1f;
$color-flame-subtitle: #b6663a;
$color-flame-text: #502d19;
$color-flame-on-default: #d49e7f;
$color-flame-on-primary: #ffffff;
$color-flame-on-secondary: #ffffff;
$color-flame-on-tertiary: #ffffff;
$color-flame-on-hover: #9b745e;
$color-flame-on-disabled: #a0a0a0;

$color-gold-darker: #6c4409;
$color-gold-dark: #bd750d;
$color-gold-base: #f89a12;
$color-gold-light: #ffe7c2;
$color-gold-lighter: #e7aec2;
$color-gold-backgrounds: #f5f4f2;
$color-gold-backgrounds-containers: #ffffff;
$color-gold-backgrounds-disabled: #e9e9e9;
$color-gold-backgrounds-hover: #fff6ea;
$color-gold-title: #c57502;
$color-gold-subtitle: #bc945b;
$color-gold-text: #392e1e;
$color-gold-on-default: #aea18d;
$color-gold-on-primary: #ffffff;
$color-gold-on-secondary: #ffffff;
$color-gold-on-tertiary: #ffffff;
$color-gold-on-hover: #827768;
$color-gold-on-hover-transparent: #82776814;
$color-gold-on-disabled: #a0a0a0;

$color-yellow-darker: #574001;
$color-yellow-dark: #b68705;
$color-yellow-base: #eeb000;
$color-yellow-light: #fdd975;
$color-yellow-lighter: #f8e7b8;
$color-yellow-backgrounds: #f8f4eb;
$color-yellow-backgrounds-containers: #ffffff;
$color-yellow-backgrounds-disabled: #e9e9e9;
$color-yellow-backgrounds-hover: #fff7e4;
$color-yellow-title: #966e00;
$color-yellow-subtitle: #c3a54f;
$color-yellow-text: #332b15;
$color-yellow-on-default: #a49772;
$color-yellow-on-primary: #ffffff;
$color-yellow-on-secondary: #ffffff;
$color-yellow-on-tertiary: #ffffff;
$color-yellow-on-hover: #746b52;
$color-yellow-on-disabled: #a0a0a0;

$color-grey-darker: #5c5c5e;
$color-grey-dark: #8a8a8a;
$color-grey-base: #c5c4ca;
$color-grey-light: #e4e4e4;
$color-grey-lighter: #f8e7b8;
$color-grey-backgrounds: #f7f7f7;
$color-grey-backgrounds-containers: #ffffff;
$color-grey-backgrounds-disabled: #e6e6e6;
$color-grey-backgrounds-hover: #ededed;
$color-grey-title: #696969;
$color-grey-subtitle: #adadad;
$color-grey-text: #494949;
$color-grey-on-default: #2d2d2d;
$color-grey-on-primary: #ffffff;
$color-grey-on-secondary: #ffffff;
$color-grey-on-tertiary: #ffffff;
$color-grey-on-hover: #545454;
$color-grey-on-disabled: #a0a0a0;

$color-white: #ffffff;
$color-black: #000000;

//Error
$color-alert-dark: #97191a;
$color-alert-base: #f24748;
$color-alert-light: #ffcfcf;

//Warning
$color-warning-dark: #bb7c0c;
$color-warning-base: #f9b43a;
$color-warning-light: #fde1b0;

//Success
$color-success-dark: #076946;
$color-success-base: #2ca14d;
$color-success-light: #caeed4;

//Infomation
$color-information-dark: #004b81;
$color-information-base: #0094ff;
$color-information-light: #c2e6ff;
