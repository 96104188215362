// Fade In
@mixin fadeIn($duration: 0.5s, $delay: 0s) {
  opacity: 0;
  animation: fadeInAnimation $duration ease-in-out $delay forwards;
}

@keyframes fadeInAnimation {
  to {
    opacity: 1;
  }
}

// Fade Out
@mixin fadeOut($duration: 0.5s, $delay: 0s) {
  opacity: 1;
  animation: fadeOutAnimation $duration ease-in-out $delay forwards;
}

@keyframes fadeOutAnimation {
  to {
    opacity: 0;
  }
}

// Slide In (from the right)
@mixin slideIn($duration: 0.5s, $delay: 0s) {
  transform: translateX(100%);
  animation: slideInAnimation $duration ease-in-out $delay forwards;
}

@keyframes slideInAnimation {
  to {
    transform: translateX(0);
  }
}

// Slide Out (to the right)
@mixin slideOut($duration: 0.5s, $delay: 0s, $distance: 100%) {
  transform: translateX(0);
  animation: slideOutAnimation $duration ease-in-out $delay forwards;
}

@keyframes slideOutAnimation {
  to {
    transform: translateX(100%);
  }
}

// Slide In (from the bottom)
@mixin slideInBottom($duration: 0.5s, $delay: 0s, $distance: 100%) {
  transform: translateY(100%);
  animation: slideInBottomAnimation $duration ease-in-out $delay forwards;
}

@keyframes slideInBottomAnimation {
  to {
    transform: translateY(0);
  }
}

// Slide Out (to the bottom)
@mixin slideOutBottom($duration: 0.5s, $delay: 0s, $distance: 100%) {
  transform: translateY(0);
  animation: slideOutBottomAnimation $duration ease-in-out $delay forwards;
}

@keyframes slideOutBottomAnimation {
  to {
    transform: translateY(100%);
  }
}
