.login {
  &__row {
    max-height: 72vh;
    height: auto;
  }
  &__col {
    background-color: $color-background-container;
    padding: 24px;
    border-radius: 20px;
    height: auto;
  }
  &__title {
    margin-bottom: 15px;
    margin-top: 0px;
    text-align: center;
  }
  &__subtitle {
    margin-top: 0px;
    margin-bottom: 4px;
    text-align: center;
  }
  &__form-item {
    margin-bottom: 15px !important;
  }
  &__form-button {
    width: 100%;
  }
}