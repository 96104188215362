.loading-component {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-background-container;
  transition: 0.3 all ease;

  &--absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-background-container;
    transition: 0.3 all ease;
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 110;
  }
  &__load-animation {
    width: 48px;
    height: 48px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &__1 {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px solid $color-primary;
      border-radius: inherit;
      border-top: 3px solid transparent;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      animation: rotatespinner 0.8s ease infinite;
      top: 0px;
    }
    &__2 {
      top: 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px dashed $color-primary;
      border-radius: inherit;
      border-top: 3px solid transparent;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      animation: rotatespinner 0.8s linear infinite;
      opacity: 0.2;
    }
    &__3 {
      display: none;
    }
  }
}

.loading-component-directive {
  overflow: hidden;
}
.loading-component-backdrop {
  position: absolute;
  background-color: transparent;
  opacity: 0.6;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

@keyframes rotatespinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes appearShow {
  0% {
    opacity: 0;
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
