.card-title {
  box-shadow: $shadow-M !important;
  border-radius: $card-border-radius;
  background-color: $color-primary;
  height: 100%;
  &:hover {
    cursor: pointer;
  }
  &__title {
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      color: $color-on-primary;
  }
}