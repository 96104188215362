.descriptions {
  .ant-descriptions-row {
    border-bottom: 1px solid $color-on-primary !important;
  }
  &__label {
    &.ant-descriptions-item-label {
      background-color: $color-primary !important;
      color: $color-on-primary !important;
      border-inline-end: 1px solid $color-on-primary !important;
    }
  }
  @include fadeIn;
}
