.protected-layout {
  flex-direction: row !important;
  &__logo {
    text-align: center;
    margin-top: 10px;
    padding: 5px;
  }
  &__header {
    padding: 20px;
    background: $color-background-container;
    min-height: 8vh;
    display: flex;
    place-items: center;
    justify-content: space-between;
  }
  &__dashboard {
    padding: 15px;
    min-height: 80vh;
    &__content {
      padding: 10px 24px;
      min-height: 80vh;
      background: $color-background-container;
      border-radius: $card-border-radius;
      box-shadow: $shadow-M;
      @include fadeIn;
    }
  }
}

@media (min-width: 200px) and (max-width: 969px) {
  .protected-layout {
    &__dashboard {
      padding: 5px;
      &__content {
        padding: 1px;
      }
    }
  }
}
