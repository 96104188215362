.summary-client {
  &__card {
    &__title {
      &__date-picker {
        margin-left: 10px;
      }
    }
    &__actions {
      &__select {
        margin-top: 5px;
        margin-bottom: 5px;
        width: 90%;
      }
    }
    @include fadeIn;
  }
  &__table {
    @include fadeIn;
  }
}

@media (min-width: 200px) and (max-width: 640px) {
  .summary-client {
    &__card {
      .ant-card-body {
        padding: 8px !important;
      }
      .ant-card-actions {
        display: grid !important;
        grid-template-columns: repeat(2, minmax(100px, 1fr)) !important;
        &::before {
          content: none !important;
        }
        &::after {
          content: none !important;
        }
        li {
          width: 100% !important;
        }
      }
    }
  }
}
