.table-style {
  .ant-table-thead .ant-table-cell {
    background: $color-primary !important;
    color: $color-on-primary;
  }

  .ant-table-thead .ant-table-selection-column {
    background: $color-primary-light !important;
  }

  &.ant-table-wrapper .ant-table-column-sorter {
    color: $color-on-primary !important;
  }
}
