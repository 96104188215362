@mixin scroll-bar {
  scrollbar-width: auto;
  scrollbar-color: $color-primary-light $color-primary-lighter !important;

  &::-webkit-scrollbar {
    height: 5px !important;
    width: 5px !important;
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary-light !important;
    border-radius: 15px !important;
  }
}
