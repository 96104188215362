.collections-form {
  &__form-item {
    margin-bottom: 10px !important;
    &__date-picker {
      width: 100%;
    }
    &__button {
      margin-bottom: 10px !important;
      text-align: center;
    }
  }
}

.collections-form-quickbooks {
  &__button {
    background-color: #2ca01c !important;
    color: #fff;
    border-color: #2ca01c;
    text-align: center;
    &:disabled {
      background-color: var(--layout) !important;
      border-color: var(--layout);
    }
    &:hover {
      color: #fff !important;
    }
  }
}
